import Vue from 'vue'

class ExpenseRefundRepository {
  periods(data) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: `/api/organization/${data.organizationId}/expense-periods`,
        method: 'post',
        data,
      }).then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }
  createCorrectiveLine(data) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: `/api/expense-lines/corrective/create`,
        method: 'post',
        data,
      }).then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  updateCorrectiveLine(id, data) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: `/api/expense-lines/corrective/update/${id}`,
        method: 'put',
        data,
      }).then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  deleteCorrectiveLine(id) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: `/api/expense-lines/corrective/delete/${id}`,
        method: 'delete',
      }).then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }


  syncPeriod(periodId) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: `/api/expense-periods/sync/${periodId}`,
        method: 'get',
      }).then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }


  sendTestMail(periodId) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: `/api/expense-periods/${periodId}/send-test-mail`,
        method: 'get',
      }).then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }


  period(periodId, data) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: `/api/expense-periods/${periodId}`,
        method: 'post',
        data,
      }).then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  memberCdrs(periodId, memberId, data) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: `/api/expense-periods/${periodId}/members/${memberId}`,
        method: 'post',
      }).then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  changeLineStatus(id, status) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: `/api/expense-lines/${id}/change-status`,
        method: 'post',
        data: {
          status
        }
      }).then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }


  periodOrganizationPdf(expensePeriodId, organizationId) {
    return new Promise((resolve, reject) => {
      Vue.auth.download({
        url: `/api/invoice/expense-report-organization-pdf`,
        method: 'post',
        data: {
          expensePeriodId,
          organizationId
        }
      }).then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }


  periodOrganizationSheet(expensePeriodId, organizationId) {
    return new Promise((resolve, reject) => {
      Vue.auth.download({
        url: `/api/invoice/expense-report-organization-sheet`,
        method: 'post',
        data: {
          expensePeriodId,
          organizationId
        }
      }).then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }


  globalSheet(month) {
    return new Promise((resolve, reject) => {
      Vue.auth.download({
        url: `/api/invoice/expense-report-global-csv`,
        method: 'post',
        data: {
          month,
        }
      }).then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }


  periodMemberPdf(expensePeriodId, memberId) {
    return new Promise((resolve, reject) => {
      Vue.auth.download({
        url: `/api/invoice/expense-report-member-pdf`,
        method: 'post',
        data: {
          expensePeriodId,
          memberId
        }
      }).then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }


}


export default new ExpenseRefundRepository()
