<template>
  <div class="member-search mt-5">

    <v-breadcrumbs
        class="level-2"
        :items="[
            {text: $t('expense-refund.expense-refunds'), to: {name: 'organizations.expense-periods'}, exact: true},
            {text: periodName },
        ]"
        divider=">"
    ></v-breadcrumbs>

    <v-row dense>
      <v-col class="text-right">




        <v-btn small @click="sendTestMail" v-if="currentUser.isZE">
          <v-icon left>mdi-send</v-icon>
          Test mail
        </v-btn>


        <v-btn small class="ml-3" @click="syncPeriod" v-if="['PENDING', 'IN_PROGRESS'].includes(status)">
          <v-icon left>mdi-sync</v-icon>
          Sync CDR
        </v-btn>


        <v-btn small class="ml-3" @click="exportGlobal" v-if="currentUser.isZE">
          <v-icon left>mdi-file-delimited</v-icon>
          Global
        </v-btn>


        <v-btn class="mx-3" small @click="exportSheet">
          <v-icon left>mdi-file-excel</v-icon>
          Xlsx
        </v-btn>

        <v-btn small @click="exportPdf">
          <v-icon left>mdi-file-pdf-box</v-icon>
          PDF
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            <h3>{{ $t('expense-refund.expense-period') }}</h3>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="6">
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field
                        :value="formatUtcDate(startDate, $t('format_date'))"
                        :label="$t('expense-refund.start-date')"
                        readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                        :value="formatUtcDate(endDate, $t('format_date'))"
                        :label="$t('expense-refund.end-date')"
                        readonly
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                    :value="status ? $t('expense-refund.status.' + status ) : '-'"
                    :label="$t('status')"
                    readonly
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>


    <v-row>
      <v-col>
        <search-field
            :placeholder="$t('search')"
            :search.sync="search"
            :options.sync="options"
            clearable
        >
        </search-field>
      </v-col>
    </v-row>



    <v-row dense v-if="hasMembersWithoutCharges">
      <v-col class=" d-flex justify-end">
        <v-switch
            v-model="showMembersWithoutCharges"
            :label="$t('expense-refund.show-members-without-recharges')"
            hide-details
        />
      </v-col>
    </v-row>


    <!-- List -->
    <v-row no-gutters class="mt-10">
      <v-col>
        <v-data-table
            class="member-list"
            :headers="headers"
            :items="memberFiltered"
            :loading="loading"
            :options="options"
        >
          <template #item.customer.lastname="{item}">
            <router-link :to="{name: 'organizations.expense-periods.member', params: {periodId: periodId, memberId: item.id}}">
              {{ item.customer?.firstname }} {{ item.customer?.lastname }}
            </router-link>
          </template>

          <template #item.energy="{value}">
            {{ numberFormat(value/ 1000) }} kWh
          </template>

          <template #item.amount="{value}">
            {{ numberFormat(value) }} €
          </template>


        </v-data-table>

      </v-col>
    </v-row>




  </div>
</template>

<script>
import OrganizationRepository from "@repository/OrganizationRepository"
import MemberGroupRepository from "@repository/MemberGroupRepository"
import ExpenseRefundRepository from "@repository/ExpenseRefundRepository";
import dayjs from "dayjs";

export default {
  data() {
    return {
      search: '',
      startDate: null,
      endDate: null,
      status: null,
      loading: false,
      autoSynced: false,
      showMembersWithoutCharges: false,
      members: [],
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['customer.lastname'],
        sortDesc: [false],
      },
    }
  },

  mounted() {
    this.loadData('init')
  },

  watch: {

  },

  computed: {

    headers() {
      return [
        {text: this.$t('name'), value: 'customer.lastname'},
        {text: this.$t('expense-refund.charge-count'), value: 'count', width: 200},
        {text: this.$t('net'), value: 'amount', width: 200},
        {text: this.$t('energy'), value: 'energy', width: 200},
      ]
    },

    hasMembersWithoutCharges() {
      return this.members.some(item => item.energy === 0)
    },

    memberFiltered() {
      let members = this.members.filter(item => {
        if(this.search) {
          let search = this.search.toLowerCase()
          if(!item.customer.firstname.toLowerCase().includes(search) && !item.customer.lastname.toLowerCase().includes(search)) {
            return false
          }
        }
        return true
      })

      return members.filter(item => {
        if(!this.showMembersWithoutCharges) {
          if(item.energy === 0) {
            return false
          }
        }
        return true
      })
    },


    organization() {
      return this.$parent.organization ?? null
    },

    periodId() {
      return this.$route.params.periodId
    },

    periodName() {
      if(this.startDate) {
        return this.formatUtcDate(this.startDate) + ' > ' + this.formatUtcDate(this.endDate)
      }
      return null
    },
  },

  methods: {


    loadData() {
      return new Promise((resolve, reject) => {
        this.loading = true

        let data = {
          searchText: this.search,
        }


        ExpenseRefundRepository.period(this.periodId, data).then(response => {
          this.startDate = response.startDate
          this.endDate = response.endDate
          this.status = response.status
          this.members = response.members
          this.loading = false

          if(this.members.length === 0) {
            if(!this.autoSynced) {
              this.autoSynced = true;
              this.syncPeriod()
            }
          }

          resolve(response)

        }).catch(err => {
          this.notifyError(err)
          this.loading = false
          reject(err)
        })
      })
    },

    exportPdf() {
      this.showLoading(true)
      ExpenseRefundRepository.periodOrganizationPdf(this.periodId, this.organization.id).then(() => {
        this.showLoading(false)
      }).catch(e => {
        this.$dialog.notify.error(e.response?.data?.message || e.message)
        this.showLoading(false)
      })
    },

    syncPeriod() {
      if(['PENDING', 'IN_PROGRESS'].includes(this.status)) {
        this.showLoading(true)
        ExpenseRefundRepository.syncPeriod(this.periodId).then(() => {
          this.showLoading(false)
          this.loadData().then(() => {
            this.$dialog.notify.success(this.$t('sync-success'))
          })
        }).catch(e => {
          this.$dialog.notify.error(e.response?.data?.message || e.message)
          this.showLoading(false)
        })
      }
    },

    sendTestMail() {
      this.showLoading(true)
      ExpenseRefundRepository.sendTestMail(this.periodId).then(() => {
        this.showLoading(false)
        this.loadData().then(() => {})
      }).catch(e => {
        this.$dialog.notify.error(e.response?.data?.message || e.message)
        this.showLoading(false)
      })
    },

    exportGlobal() {
      let month = dayjs(this.endDate).format('YYYY-MM');
      console.log(month)
      this.showLoading(true)
      ExpenseRefundRepository.globalSheet(month).then(() => {
        this.showLoading(false)
      }).catch(e => {
        this.$dialog.notify.error(e.response?.data?.message || e.message)
        this.showLoading(false)
      })
    },

    exportSheet() {
      this.showLoading(true)
      ExpenseRefundRepository.periodOrganizationSheet(this.periodId, this.organization.id).then(() => {
        this.showLoading(false)
      }).catch(e => {
        this.$dialog.notify.error(e.response?.data?.message || e.message)
        this.showLoading(false)
      })
    },
  },
}
</script>

<style lang="scss">
.member-search {
  min-height: 1000px;
  .member-list {
    td, th {
      cursor: pointer;
      &.text-start {
        text-align: left !important;
      }
    }
  }
}
</style>
