var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"subscription-form pa-10"},[_c('v-breadcrumbs',{staticClass:"level-2",attrs:{"items":[
          {text: _vm.$tc('subscription.subscription', 2), to: {name: 'organizations.subscriptions'}, exact: true},
          {text: _vm.$t('subscription.manage') },
      ],"divider":">"}}),(_vm.form)?_c('v-row',{staticClass:"mt-10"},[_c('v-col',[_c('v-form',{ref:"form",staticClass:"form-grid",on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_c('headline',[_vm._v(_vm._s(_vm.$t('organization.general-information')))]),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('form-field',{attrs:{"type":"switch","label":_vm.$tc('enable'),"col-field":9},model:{value:(_vm.form.enabled),callback:function ($$v) {_vm.$set(_vm.form, "enabled", $$v)},expression:"form.enabled"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('form-field',{attrs:{"type":"select","items":[
                    _vm.form.subscriptionPlan,
                ],"label":_vm.$tc('subscription.subscription-plan'),"rules":[
                    _vm.commonRules.required,
                ],"item-text":"name","return-object":"","disabled":""},model:{value:(_vm.form.subscriptionPlan),callback:function ($$v) {_vm.$set(_vm.form, "subscriptionPlan", $$v)},expression:"form.subscriptionPlan"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('form-field',{attrs:{"type":"select","items":_vm.billingCycles,"label":_vm.$tc('subscription.billing-cycle'),"rules":[
                    _vm.commonRules.required,
                ]},model:{value:(_vm.form.billingCycle),callback:function ($$v) {_vm.$set(_vm.form, "billingCycle", _vm._n($$v))},expression:"form.billingCycle"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('form-field',{attrs:{"type":"date","label":_vm.$tc('subscription.activation-start-date'),"rules":[
                    _vm.commonRules.required,
                ]},model:{value:(_vm.form.activationStartDate),callback:function ($$v) {_vm.$set(_vm.form, "activationStartDate", $$v)},expression:"form.activationStartDate"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('form-field',{attrs:{"type":"date","label":_vm.$tc('subscription.billing-start-date'),"rules":[
                    _vm.commonRules.required,
                ]},model:{value:(_vm.form.billingStartDate),callback:function ($$v) {_vm.$set(_vm.form, "billingStartDate", $$v)},expression:"form.billingStartDate"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('form-field',{attrs:{"type":"price-index-select","label":_vm.$tc('price-index.price-index'),"clearable":""},model:{value:(_vm.form.priceIndexId),callback:function ($$v) {_vm.$set(_vm.form, "priceIndexId", $$v)},expression:"form.priceIndexId"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[(_vm.form.priceIndexValue)?_c('form-field',{attrs:{"disabled":"","label":_vm.$tc('price-index.used-index'),"clearable":""},model:{value:(_vm.form.priceIndexValue),callback:function ($$v) {_vm.$set(_vm.form, "priceIndexValue", $$v)},expression:"form.priceIndexValue"}}):_vm._e()],1)],1),(_vm.form.subscriptionPlan?.customFieldsDefinition?.length > 0)?[_c('v-row',[_c('v-col',[_c('custom-fields',{attrs:{"fields":_vm.form.subscriptionPlan.customFieldsDefinition,"col-field":9},model:{value:(_vm.form.customFields),callback:function ($$v) {_vm.$set(_vm.form, "customFields", $$v)},expression:"form.customFields"}})],1)],1)]:_vm._e(),_c('headline',{staticClass:"mt-12"},[_vm._v(" "+_vm._s(_vm.$tc('subscription.formula', 2))+" ")]),_c('v-row',{staticClass:"grid"},[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.form.subscriptionPlan.formulas,"options":{
                  itemsPerPage: 1000
                },"hide-default-footer":"","disable-sort":""},scopedSlots:_vm._u([{key:"item.label",fn:function({value}){return [_vm._v(" "+_vm._s(_vm.localizedString(value))+" ")]}},{key:"item.filters",fn:function({value}){return [_vm._l((_vm.sortItems(value, 'filters')),function(item){return [_c('v-chip',{key:item,staticClass:"my-1 mr-2",attrs:{"small":"","outlined":""}},[_vm._v(" "+_vm._s(item)+" ")])]})]}},{key:"item.properties",fn:function({value}){return [_vm._l((_vm.sortItems(value, 'properties')),function(item){return [_c('v-chip',{key:item,staticClass:"my-1 mr-2",attrs:{"small":"","outlined":"","color":"blue"}},[_vm._v(" "+_vm._s(item)+" ")])]})]}},{key:"item.price",fn:function({item}){return [_c('v-text-field',{attrs:{"type":"number","hide-details":"","min":"0"},model:{value:(_vm.formulas[item.id].price),callback:function ($$v) {_vm.$set(_vm.formulas[item.id], "price", _vm._n($$v))},expression:"formulas[item.id].price"}})]}},{key:"item.enabled",fn:function({item}){return [_c('v-switch',{attrs:{"hide-details":""},model:{value:(_vm.formulas[item.id].enabled),callback:function ($$v) {_vm.$set(_vm.formulas[item.id], "enabled", $$v)},expression:"formulas[item.id].enabled"}})]}}],null,false,1886551400)})],1)],1),_c('headline',{staticClass:"mt-12"},[_vm._v(" "+_vm._s(_vm.$tc('subscription.option', 2))+" ")]),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.form.subscriptionPlan.options,"options":{
                itemsPerPage: 1000
              },"hide-default-footer":"","disable-sort":""},scopedSlots:_vm._u([{key:"item.label",fn:function({value}){return [_vm._v(" "+_vm._s(_vm.localizedString(value))+" ")]}},{key:"item.filters",fn:function({value}){return [_vm._l((value),function(item){return [_c('v-chip',{key:item,staticClass:"my-1 mr-2",attrs:{"small":"","outlined":""}},[_vm._v(" "+_vm._s(_vm.$t(`enums.subscription-plan-filters.${item}`))+" ")])]})]}},{key:"item.properties",fn:function({value}){return [_vm._l((value),function(item){return [_c('v-chip',{key:item,staticClass:"my-1 mr-2",attrs:{"small":"","outlined":"","color":"blue"}},[_vm._v(" "+_vm._s(_vm.$t(`enums.subscription-plan-properties.${item}`))+" ")])]})]}},{key:"item.price",fn:function({item}){return [_c('v-text-field',{attrs:{"type":"number","hide-details":"","min":"0"},model:{value:(_vm.options[item.id].price),callback:function ($$v) {_vm.$set(_vm.options[item.id], "price", _vm._n($$v))},expression:"options[item.id].price"}})]}},{key:"item.enabled",fn:function({item}){return [_c('v-switch',{attrs:{"hide-details":""},model:{value:(_vm.options[item.id].enabled),callback:function ($$v) {_vm.$set(_vm.options[item.id], "enabled", $$v)},expression:"options[item.id].enabled"}})]}}],null,false,1848384392)})],1)],1),_c('headline',{staticClass:"mt-16"},[_vm._v(" "+_vm._s(_vm.$tc('subscription.email-recipient', 2))+" ")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('form-field',{attrs:{"type":"user-select","label":_vm.$tc('user.users', 2),"multiple":"","chips":"","deletable-chips":"","clearable":""},model:{value:(_vm.form.emailRecipients),callback:function ($$v) {_vm.$set(_vm.form, "emailRecipients", $$v)},expression:"form.emailRecipients"}})],1)],1),_c('v-row',{staticClass:"mt-6"},[_c('v-col',{staticClass:"text-center"},[_c('v-btn',{staticClass:"ml-3",attrs:{"color":"primary","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('save'))+" ")])],1)],1)],2)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }