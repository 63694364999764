<template>
  <div class="subscription-form pa-10">

    <v-breadcrumbs
        class="level-2"
        :items="[
            {text: $tc('subscription.subscription', 2), to: {name: 'organizations.subscriptions'}, exact: true},
            {text: $t('subscription.manage') },
        ]"
        divider=">"
    ></v-breadcrumbs>

    <v-row class="mt-10" v-if="form">
      <v-col>
        <v-form ref="form" class="form-grid" @submit.prevent="save">
          <headline>{{ $t('organization.general-information') }}</headline>
          <v-row>
            <v-col cols="12">

              <form-field
                  type="switch"
                  v-model="form.enabled"
                  :label="$tc('enable')"
                  :col-field="9"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <form-field
                  type="select"
                  v-model="form.subscriptionPlan"
                  :items="[
                      form.subscriptionPlan,
                  ]"
                  :label="$tc('subscription.subscription-plan')"
                  :rules="[
                      commonRules.required,
                  ]"
                  item-text="name"
                  return-object
                  disabled
              />
            </v-col>

            <v-col cols="12" md="6">
              <form-field
                  type="select"
                  v-model.number="form.billingCycle"
                  :items="billingCycles"
                  :label="$tc('subscription.billing-cycle')"
                  :rules="[
                      commonRules.required,
                  ]"
              >
              </form-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <form-field
                  type="date"
                  v-model="form.activationStartDate"
                  :label="$tc('subscription.activation-start-date')"
                  :rules="[
                      commonRules.required,
                  ]"
              />
            </v-col>
            <v-col cols="12" md="6">
              <form-field
                  type="date"
                  v-model="form.billingStartDate"
                  :label="$tc('subscription.billing-start-date')"
                  :rules="[
                      commonRules.required,
                  ]"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <form-field
                  type="price-index-select"
                  :label="$tc('price-index.price-index')"
                  v-model="form.priceIndexId"
                  clearable
              />
            </v-col>
            <v-col cols="12" md="6">
              <form-field
                  v-if="form.priceIndexValue"
                  disabled
                  :label="$tc('price-index.used-index')"
                  v-model="form.priceIndexValue"
                  clearable
              />
            </v-col>
          </v-row>



          <template v-if="form.subscriptionPlan?.customFieldsDefinition?.length > 0">
            <v-row>
              <v-col>
                <custom-fields
                    v-model="form.customFields"
                    :fields="form.subscriptionPlan.customFieldsDefinition"
                    :col-field="9"
                />
              </v-col>
            </v-row>
          </template>


          <headline class="mt-12">
            {{ $tc('subscription.formula', 2) }}
          </headline>
          <v-row class="grid">
            <v-col>
              <v-data-table
                  :headers="headers"
                  :items="form.subscriptionPlan.formulas"
                  :options="{
                    itemsPerPage: 1000
                  }"
                  hide-default-footer
                  disable-sort
              >

                <template #item.label="{value}">
                  {{ localizedString(value) }}
                </template>

                <template #item.filters="{value}">
                  <template v-for="item in sortItems(value, 'filters')">
                    <v-chip small outlined :key="item"  class="my-1 mr-2">
                      {{ item }}
                    </v-chip>
                  </template>
                </template>

                <template #item.properties="{value}">
                  <template v-for="item in sortItems(value, 'properties')" >
                    <v-chip  small outlined  color="blue" :key="item"  class="my-1 mr-2">
                      {{ item }}
                    </v-chip>
                  </template>
                </template>

                <template #item.price="{item}">
                  <v-text-field
                      type="number"
                      v-model.number="formulas[item.id].price"
                      hide-details
                      min="0"
                  >
                  </v-text-field>
                </template>

                <template #item.enabled="{item}">
                  <v-switch
                      v-model="formulas[item.id].enabled"
                      hide-details
                  />
                </template>

              </v-data-table>
            </v-col>
          </v-row>

          <headline class="mt-12">
            {{ $tc('subscription.option', 2) }}
          </headline>
          <v-row>
            <v-col>
              <v-data-table
                :headers="headers"
                :items="form.subscriptionPlan.options"
                :options="{
                  itemsPerPage: 1000
                }"
                hide-default-footer
                disable-sort
              >

                <template #item.label="{value}">
                  {{ localizedString(value) }}
                </template>

                <template #item.filters="{value}">
                  <template v-for="item in value" >
                    <v-chip  small outlined :key="item"  class="my-1 mr-2">
                      {{ $t(`enums.subscription-plan-filters.${item}`) }}
                    </v-chip>
                  </template>
                </template>

                <template #item.properties="{value}">
                  <template v-for="item in value" >
                    <v-chip small outlined  color="blue" :key="item"  class="my-1 mr-2">
                      {{ $t(`enums.subscription-plan-properties.${item}`) }}
                    </v-chip>
                  </template>
                </template>

                <template #item.price="{item}">
                  <v-text-field
                      type="number"
                      v-model.number="options[item.id].price"
                      hide-details
                      min="0"
                  >
                  </v-text-field>
                </template>

                <template #item.enabled="{item}">
                  <v-switch
                      v-model="options[item.id].enabled"
                      hide-details
                  />
                </template>


              </v-data-table>
            </v-col>
          </v-row>

          <headline class="mt-16">
            {{ $tc('subscription.email-recipient', 2)}}
          </headline>
          <v-row>
            <v-col cols="12" md="6">
              <form-field
                  type="user-select"
                  :label="$tc('user.users', 2)"
                  v-model="form.emailRecipients"
                  multiple
                  chips
                  deletable-chips
                  clearable
              />
            </v-col>
          </v-row>


          <v-row class="mt-6">
            <v-col class="text-center">
              <v-btn class="ml-3" color="primary" type="submit">
                {{ $t('save') }}
              </v-btn>
            </v-col>
          </v-row>

        </v-form>


      </v-col>
    </v-row>

  </div>
</template>

<script>
import SubscriptionRepository from "@repository/SubscriptionRepository";
import CustomFields from "@blocks/CustomFields.vue";
import RulesMixin from "@mixins/RulesMixin";

export default {
  components: {CustomFields},

  data() {
    return {
      form: null,
      headers: [
        {text: this.$t('name'), value: 'name', width: 300},
        {text: this.$t('filters'), value: 'filters'},
        {text: this.$t('properties'), value: 'properties'},
        {text: this.$t('price-ht'), value: 'price', width: 150},
        {text: this.$t('enable'), value: 'enabled', width: 80},
      ],
      formulas: {},
      options: {},
    }
  },

  mixins: [
      RulesMixin,
  ],

  mounted() {
    if(this.isEdit) {
      this.loadData()
    }
  },

  watch: {
    formulas: {
      handler(value) {
        this.setFormulas(value)
      },
      deep: true
    },

    form: {
      handler() {
        this.setUnsavedChanges()
      },
      deep: true
    },

    options: {
      handler(value) {
        this.setOptions(value)
      },
      deep: true
    },
  },

  computed: {
    id() {
      return this.$route.params.subscriptionId ?? null
    },

    isEdit() {
      return !!this.id
    },

    organization() {
      return this.$parent.organization ?? null
    },

    billingCycles() {
      const items = []
      let types = this.$t('enums.billing-cycle');
      for(let type in types) {
        items.push({text: types[type], value: type})
      }
      return items
    },
  },

  methods: {

    getFormulas() {
      const formulas = {}
      for(const f of this.form?.formulas) {
        formulas[f.subscriptionPlanFormulaId] = {
          id: f.id,
          price: f.price,
          enabled: f.enabled,
        }
      }
      return formulas
    },

    setFormulas(value) {
      const formulas = []
      for (const [k, v] of Object.entries(value)) {
        formulas.push({
          id: v.id,
          subscriptionPlanFormulaId: k,
          price: v.price ? v.price : null,
          enabled: v.enabled,
        })
      }
      this.form.formulas = formulas
    },

    getOptions() {
      const options = {}
      for(const o of this.form?.options) {
        options[o.subscriptionPlanOptionId] = {
          id: o.id,
          price: o.price,
          enabled: o.enabled,
        }
      }
      return options
    },

    setOptions(value) {
      const options = []
      for (const [k, v] of Object.entries(value)) {
        options.push({
          id: v.id,
          subscriptionPlanOptionId: k,
          price: v.price ? v.price : null,
          enabled: v.enabled,
        })
      }
      this.form.options = options
    },

    loadData() {
      this.showLoading(true)
      SubscriptionRepository.get(this.id).then(data => {

        console.log(data)
        if(data.customFields?.length === 0) {
          data.customFields = data.subscriptionPlan.customFields
        } else {
          const defaultCustomFields = {}
          if(data.subscriptionPlan?.customFields) {
            for(const f of data.subscriptionPlan?.customFields) {
              defaultCustomFields[f.customFieldId] = f.value
            }
          }
          if(data.customFields) {
            for(const f of data.customFields) {
              if(!f.value) {
                f.value = defaultCustomFields[f.id]
              }
            }
          }
        }

        this.form = data
        this.formulas = this.getFormulas()
        this.options = this.getOptions()

        this.$nextTick(() => {
          this.cleanUnsavedChanges()
        })

        this.showLoading(false)
      }).catch(err => {
        this.$dialog.notify.error(err.response.data?.message || err.message)
        this.showLoading(false)
      })

      SubscriptionRepository.countLicences(this.id).then(data => {

      }).catch(err => {
        this.notifyError(err)
      })
    },

    sortItems(items, type) {
      return items.map(i => this.$t(`enums.subscription-plan-${type}.${i}`)).toSorted()
    },


    save() {
      if(this.$refs.form.validate()) {
        this.showLoading(true)
        SubscriptionRepository.update(this.id, this.form).then(response => {
          this.showLoading(false)
          this.$parent.loadTabs()
          this.$dialog.notify.success(this.$t('update-success'))
          this.cleanUnsavedChanges()
          this.$router.push({name: 'organizations.subscriptions'})
        }).catch(err => {
          this.$dialog.notify.error(err.response?.data?.message || err.message)
          this.showLoading(false)
        })
      }
    },

  },

}
</script>

<style lang="scss">
  .subscription-form {

  }
</style>
